.post .article-v2 {
  padding: 0 15px;
}

.post .article-v2 .entry-title {
  font-size: 16px;
  color: #666666;
  font-weight: 600;
}

.post .article-v2 .article-header {
  margin-bottom: 15px;
}

.post .article-v2 .article-content-main {
  -webkit-box-shadow: 1px 0px 20px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 1px 0px 20px 1px rgba(0, 0, 0, 0.15);
  padding: 30px;
  position: relative;
  background: #fff;
  margin-top: -75px;
}

@media only screen and (max-width: 1024px) {
  .post .article-v2 .article-content-main {
    padding: 15px;
    margin-bottom: 30px;
  }
}

.post .article-v2 .article-content p:last-of-type {
  margin-bottom: 0;
}

.post .article-v2 .article-thumb {
  position: relative;
  left: 25px;
  margin-bottom: 0;
}

.post .article-v2 .article-footer {
  border-top: 1px solid #ebebeb;
  padding-top: 15px;
  margin-top: 20px;
}

.post .article-v2 .article-footer .more-link {
  color: #ffa800;
}

.post .article-v2 .article-footer .footer-meta a {
  color: #aaa;
  font-size: 15px;
  margin-right: 5px;
}

.post .article-v2 .article-footer .footer-meta a i {
  font-size: 16px;
  margin-left: 2px;
}
